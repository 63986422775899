@import './colors';
@import './mixins';

.qareview-segment {
  padding: 16px 0px;
  border-top: 1px solid $primaryLightGray;

  &-title {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $primaryDeepGreen;
  }

  &-description {
    margin: 0px;
    margin-bottom: 16px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $primaryDarkGray;
  }

  &-answerdescription {
    margin: 0px;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $primaryLightGreen;
  }

  &-description,
  &-answerdescription {
    strong {
      font-weight: 500;
      color: $primaryDeepGreen;
    }
  }

  &-answerlist {
    margin: 0px;
    margin-top: 8px;
    padding-left: 16px;
  }
}
