@import './colors';
@import './mixins';

.answer {
  &-collector {
    min-height: 25vh;

    &-pod {
      .form-field-trigger {
        border: none;
        padding: 0px;
        cursor: pointer;
        padding-left: 4px;
        background-color: transparent;
      }

      .react-calendar {
        margin: 0px auto;
      }
    }
  }

  &-radio {
    display: flex;
    cursor: pointer;
    padding: 4px 0px;
    margin: -4px 0px;
    position: relative;
    margin-bottom: 16px;
    align-items: flex-start;
    justify-content: flex-start;

    &:last-child {
      margin-bottom: -4px;
    }

    &-circle {
      min-width: 16px;
      min-height: 16px;
      margin-top: 3px;
      margin-right: 12px;
      border-radius: 50%;
      position: relative;
      display: inline-block;
      border: 1px solid $primaryLightGreen;
      background-color: $primaryLightGray;
    }

    &-input {
      top: 1px;
      left: 1px;
      width: 1px;
      height: 1px;
      border: none;
      opacity: 0.1;
      position: absolute;
      background-color: transparent;

      &:checked {
        & ~ .answer-radio {
          &-text {
            color: $primaryLightGreen;
          }

          &-circle {
            &::after {
              top: 50%;
              left: 50%;
              width: 8px;
              z-index: 1;
              content: '';
              height: 8px;
              display: block;
              position: absolute;
              border-radius: 50%;
              transform: translate(-50%, -50%);
              @include greenGradient;
            }
          }
        }
      }
    }

    &-text {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: $primaryDarkGray;
    }
  }

  &-checkbox {
    display: flex;
    cursor: pointer;
    padding: 4px 0px;
    margin: -4px 0px;
    position: relative;
    margin-bottom: 16px;
    align-items: flex-start;
    justify-content: flex-start;

    &:last-child {
      margin-bottom: -4px;
    }

    &-square {
      width: 16px;
      height: 16px;
      display: block;
      margin-top: 3px;
      margin-right: 12px;
      border-radius: 4px;
      position: relative;
      border: 1px solid $primaryLightGreen;
      background-color: $primaryLightGray;
    }

    &-input {
      top: 1px;
      left: 1px;
      width: 1px;
      height: 1px;
      border: none;
      opacity: 0.1;
      position: absolute;
      background-color: transparent;

      &:checked {
        & ~ .answer-checkbox {
          &-text {
            color: $primaryLightGreen;
          }

          &-square {
            &::after {
              top: 50%;
              left: 50%;
              width: 8px;
              z-index: 1;
              content: '';
              height: 8px;
              display: block;
              position: inherit;
              border-radius: 50%;
              transform: translate(-50%, -50%);
              background: url('../assets/images/check-icon-green.svg') center
                no-repeat;
            }
          }
        }
      }
    }

    &-text {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: $primaryDarkGray;
    }
  }
}
