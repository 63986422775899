@import './colors';
@import './mixins';

.title-header {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  &-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &-right {
    margin-left: 32px;
  }

  &-heading {
    font-weight: 600;
    font-size: 1.75rem;
    margin-bottom: 8px;
    line-height: 2.625rem;
    color: $primaryDeepGreen;
  }

  &-description {
    margin: 0px;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $primaryDarkGray;
  }
}
