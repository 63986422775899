@import './colors';
@import './mixins';

.feature-modal {
  left: 0px;
  z-index: 4;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: fixed;
  margin: 0px auto;
  align-items: flex-end;
  background: transparentize($white, 0.15);

  @include responsiveAbove($mobileMax) {
    justify-content: center;
  }

  &-workarea {
    width: 100%;
    max-height: 75%;
    overflow-y: auto;
    position: inherit;
    padding: 40px 24px;
    padding-top: 24px;
    background-color: $white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: 0px -2px 8px 8px rgba(0, 0, 0, 0.035);

    @include responsiveAbove($mobileMax) {
      max-height: 85%;
      max-width: 474px;
    }
  }

  &-header {
    display: flex;
    margin-bottom: 32px;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-close {
    border: 0px;
    width: 24px;
    padding: 4px;
    height: 24px;
    display: flex;
    cursor: pointer;
    margin-left: 16px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .close-icon {
      width: 14px;
      height: 14px;
      object-fit: cover;
    }
  }
}
