.auth-choice {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &-title {
    font-weight: 600;
    font-size: 1.75rem;
    margin-bottom: 40px;
    line-height: 2.625rem;
  }

  &-description {
    margin: 0px;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 1.5rem;
    color: $primaryDarkGray;
  }

  &-options {
    width: 100%;
    display: flex;
    padding: 0px 32px;
    align-items: center;
    flex-direction: column;

    .secondary-cta {
      margin-bottom: 24px;
    }
  }
}
