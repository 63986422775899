@import './colors';
@import './mixins';

.surveys {
  padding: 62px 24px;

  @include responsiveBelow($mobileMax) {
    padding: 32px 16px;
  }

  .status {
    &-completed {
      color: $primaryLightGreen;
    }

    &-pending {
      color: $primaryDeepOrange;
    }
  }

  &-begin {
    .surveys-meta {
      margin-bottom: 32px;
    }

    &-subsection {
      min-height: 25vh;

      .subsection-heading {
        margin-bottom: 16px;
      }

      .bool-cta {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }

    .primary-cta {
      margin-top: 32px;
    }

    &-modaltitle {
      flex: 1;
      line-height: 2rem;
      font-size: 1.375rem;
      color: $primaryDeepGreen;
    }
  }

  &-performer {
    .surveys-meta {
      margin-bottom: 32px;
    }
  }

  &-subtext {
    margin: 0px;
    margin-bottom: 40px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: transparentize($primaryDarkGray, 0.25);
  }

  .menu-header {
    margin-bottom: 16px;
  }

  .form-field {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .tabular-info {
    &-withheader {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &-line {
      .link-cta {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }

  &-content {
    .progress-question {
      padding: 16px 0px;
      border-top: 1px solid $primaryLightGray;
      border-bottom: 1px solid $primaryLightGray;
    }

    &-info,
    &-uploads {
      padding-top: 24px;
      border-top: 1px solid $primaryLightGray;
    }

    &-info {
      min-height: 25vh;
    }

    &-uploads {
      margin-bottom: 24px;

      .upload-area {
        margin-bottom: 16px;
      }
    }

    &-subtitle {
      font-weight: 500;
      margin-bottom: 24px;
      font-size: 1.125rem;
      line-height: 1.625rem;
      color: $primaryDeepGreen;
    }

    &-options {
      display: flex;
      margin-top: 32px;
      align-items: center;
      justify-content: space-between;

      .link-cta {
        margin-right: 16px;
        color: $primaryDeepOrange;
      }

      .primary-cta {
        margin-left: 16px;
      }
    }

    &-questions {
      .question-describer {
        margin-bottom: 24px;
      }
    }

    &-description {
      margin: 0px;
      padding: 0px;
      padding-left: 16px;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: $primaryDarkGray;

      strong {
        font-weight: 500;
        color: $primaryDeepGreen;
      }
    }

    &-review {
      min-height: 45vh;
    }
  }

  &-success {
    &-header {
      display: flex;
      margin-bottom: 32px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      font-weight: 600;
      margin-bottom: 8px;
      font-size: 1.75rem;
      text-align: center;
      line-height: 2.625rem;
      color: $primaryDeepGreen;

      @include responsiveBelow($mobileMax) {
        font-size: 1.65rem;
        line-height: 2.425rem;
      }
    }

    &-description {
      max-width: 85%;
      margin: 0px auto;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.625rem;
      color: $primaryDarkGray;
    }

    &-illustration {
      display: flex;
      margin-bottom: 24px;
      align-items: center;
      justify-content: center;
    }

    .surveys-content-options {
      margin-top: 40px;
    }
  }
}
