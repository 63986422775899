@import './colors';
@import './mixins';

.profile {
  padding: 62px 24px;

  @include responsiveBelow($mobileMax) {
    padding: 32px 16px;
  }

  .menu-header {
    margin-bottom: 16px;
  }

  &-subtext {
    margin: 0px;
    margin-bottom: 40px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: transparentize($primaryDarkGray, 0.25);
  }

  &-meta {
    margin-bottom: 24px;
    padding-bottom: 32px;
    border-bottom: 1px solid $primaryLightGray;
  }

  &-content {
    &-title {
      font-weight: 500;
      margin-bottom: 16px;
      font-size: 1.125rem;
      line-height: 1.625rem;
      color: $primaryDeepGreen;
    }
  }

  .secondary-cta {
    margin-top: 32px;
  }
}
