@import './colors';
@import './mixins';

.teacher-record {
  border-radius: 6px;
  margin-bottom: 16px;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #27963c 0%, #87ce26 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &:last-child {
    margin-bottom: 0px;
  }

  &-header,
  &-content {
    padding: 0px 16px;
  }

  &-header {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #27963c 0%, #87ce26 100%);
  }

  &-content {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &-teachername {
    color: $white;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  &-remove {
    width: 12px;
    border: 0px;
    height: 12px;
    padding: 0px;
    cursor: pointer;
    background-color: transparent;

    .cross-icon {
      width: 100%;
      object-fit: fill;
    }
  }

  &-information {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &-infotitle {
    display: block;
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $primaryDarkGray;
  }

  &-infodescription {
    font-weight: 500;
    line-height: 1.25rem;
    font-size: 0.875rem;
    color: $primaryDeepGreen;
  }
}
