@import './colors';
@import './mixins';

.progress-header {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;

  &-back {
    border: 0px;
    display: flex;
    cursor: pointer;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
    padding: 4px 4px 4px 0px;
    background-color: $white;
  }

  &-track {
    flex: 1;
    height: 8px;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
    border: 1px solid rgba(39, 150, 60, 0.2);
    background-color: rgba(39, 150, 60, 0.15);
  }

  &-bar {
    top: 0;
    left: 0;
    z-index: 2;
    height: 6px;
    border-radius: 6px;
    position: absolute;
    width: calc(0% + 24px);
    background-color: $primaryLightGreen;
    border: 1px solid rgba(39, 150, 60, 0.2);
  }
}
