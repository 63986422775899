@import './colors';
@import './variables';

* {
  outline: none;
  user-select: none;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-weight: 400;
  color: $primaryDarkGray;
  font-family: 'Poppins', sans-serif !important;
}

#application-root {
  width: 100%;
  height: 100%;
  position: relative;
}

.responsive-container {
  width: 100%;
  height: 100%;
  margin: 0px auto;

  @include responsiveAbove($mobileMax) {
    max-width: 474px;
  }
}

// Code for Preventing Arrows in Input Type Number

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
