@import './colors';
@import './mixins';

.splash-landing {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &-content {
    flex: 1;
    display: flex;
    padding-top: 35%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    @include responsiveBelow($mobileMax) {
      padding-top: 25%;
      margin-bottom: 40px;
    }
  }

  &-title {
    margin-top: 48px;
    font-weight: 600;
    font-size: 1.75rem;
    text-align: center;
    line-height: 2.875rem;
    color: $primaryDeepGreen;
  }

  &-footer {
    width: 100%;
    display: flex;
    padding: 0px 32px;
    align-items: center;
    padding-bottom: 25%;
    justify-content: center;

    @include responsiveBelow($mobileMax) {
      padding-bottom: 48px;
    }
  }
}
