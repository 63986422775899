@import './colors';
@import './mixins';

.menu-overlay {
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  bottom: 0px;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  justify-content: flex-start;

  &-wrapper {
    flex: 1;
    width: 100%;
    padding: 32px 0px;

    @include responsiveAbove($mobileMax) {
      max-width: 474px;
      padding: 62px 0px;
    }
  }

  &-header {
    width: 100%;
    display: flex;
    padding: 0px 24px;
    align-items: center;
    margin-bottom: 48px;
    justify-content: space-between;
  }

  &-title {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.625rem;
    color: $primaryDeepGreen;
  }

  &-close {
    padding: 4px;
    border: none;
    display: flex;
    cursor: pointer;
    margin-right: -4px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &icon {
      width: 24px;
      height: 24px;
    }
  }

  &-options {
    flex: 1;
    width: 100%;
  }

  &-option {
    width: 100%;
    border: none;
    padding: 24px;
    display: block;
    cursor: pointer;
    text-align: left;
    font-weight: 500;
    position: relative;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $primaryDeepGreen;
    background-color: transparent;
    border-bottom: 1px solid $primaryLightGray;

    &:last-child {
      border-bottom: 0px;
    }

    &::after {
      top: 50%;
      right: 24px;
      content: '';
      width: 12px;
      height: 14px;
      display: block;
      position: absolute;
      background: url('../assets/images/caret-left.svg') center no-repeat;
      background-size: contain;
      transform: rotate(-180deg) translateY(50%);
    }
  }
}
