// Media for Widths Above Specified Width
@mixin responsiveAbove($minWidth) {
  @media only screen and (min-width: $minWidth) {
    @content;
  }
}

// Media for Widths Below Specified Width
@mixin responsiveBelow($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

// Media for Widths Between Specified Width's
@mixin responsiveBetween($minWidth, $maxWidth) {
  @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}
