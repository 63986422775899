@import './colors';
@import './mixins';
@import './variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  color: $primaryDeepGreen;
}

::placeholder {
  color: rgba(0, 0, 0, 0.2) !important;
}

.noscroll {
  overflow: hidden;
}

.highlight-pattern {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 432px;
  position: absolute;
  background: url('../assets/images/splash-background.svg') top repeat-x;
}

.primary-cta,
.secondary-cta {
  color: $white;
  display: block;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 16px 24px;
  border-radius: 34px;
  line-height: 1.25rem;
  @include greenGradient;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 20px rgba(40, 151, 61, 0.2);

  &:disabled {
    opacity: 0.75;
    filter: grayscale(100%);
  }

  &-fullwidth {
    width: 100%;
    max-width: 362px;
  }

  &-centered {
    margin-left: auto;
    margin-right: auto;
  }
}

.secondary-cta {
  color: $primaryLightGreen;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #27963c 0%, #87ce26 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &-fullwidth {
    width: 100%;
    max-width: 362px;
  }
}

.link-cta {
  border: 0px;
  padding: 0px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  line-height: 1.5rem;
  display: inline-block;
  text-overflow: ellipsis;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: $primaryLightGreen;
  background-color: transparent;

  &-fullwidth {
    width: 100%;
    max-width: 362px;
  }

  &-centered {
    margin-left: auto;
    margin-right: auto;
  }

  &-withplusicon {
    position: relative;
    padding-right: 24px;

    &::after {
      top: 50%;
      right: 6px;
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      transform: translateY(-50%);
      background: url('/assets/images/plus-icon.svg') center no-repeat;
      background-size: contain;
    }
  }

  &-withcareticon {
    position: relative;
    padding-right: 24px;

    &::after {
      top: 50%;
      right: 6px;
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      transform: translateY(-50%) rotate(180deg);
      background: url('/assets/images/caret-left.svg') center no-repeat;
      background-size: contain;
    }
  }
}

.bool-cta {
  cursor: pointer;
  font-weight: 500;
  padding: 8px 24px;
  font-size: 0.875rem;
  border-radius: 30px;
  line-height: 1.25rem;
  color: $primaryDarkGray;
  background-color: $white;
  border: 2px solid $primaryDarkGray;

  &-selected {
    color: $white;
    border: 2px solid $primaryLightGreen;
    background-color: $primaryLightGreen;
  }
}

.form-field {
  cursor: text;
  display: flex;
  margin-top: 10px;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  border-radius: 30px;
  background-color: $white;
  justify-content: space-between;
  border: 2px solid $primaryLightGray;

  &-label {
    top: 0px;
    left: 24px;
    padding: 0px 6px;
    position: absolute;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: $white;
    transform: translateY(-50%);
  }

  &-input {
    flex: 1;
    padding: 0px;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: $primaryDeepGreen;
  }

  &-actionable {
    .form-field-input {
      margin-right: 24px;
    }
  }

  &-focused {
    border: 2px solid $primaryLightGreen;

    .form-field-label {
      color: $primaryLightGreen;
    }
  }

  &-dirty {
    border: 2px solid $primaryBrightGreen;

    .form-field-label {
      color: $primaryBrightGreen;
    }
  }
}

.form-textarea {
  .form-field-input {
    resize: none;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 8px;
    line-height: 1.5rem;
    color: $primaryDeepGreen;
    font-family: 'Poppins', sans-serif !important;
  }

  &-wordcount {
    bottom: 8px;
    right: 32px;
    position: absolute;
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: $primaryDarkGray;
  }
}

.dropdown-field {
  position: relative;

  .form-field {
    &-input {
      cursor: pointer;
      padding-right: 30px;
    }

    &::after {
      z-index: 2;
      right: 24px;
      width: 14px;
      height: 8px;
      content: '';
      cursor: pointer;
      position: absolute;
      top: calc(50% - 4px);
      display: inline-block;
      transform: translateX(-50%);
      background: url('/assets/images/caret-down.svg') top center no-repeat;
      background-size: contain;
    }
  }

  &-dropdown {
    left: 0;
    top: 100%;
    z-index: 4;
    width: 100%;
    display: none;
    overflow: hidden;
    overflow-y: auto;
    padding: 0px 0px;
    max-height: 200px;
    box-shadow: none;
    position: absolute;
    background-color: $white;
    border: 2px solid $primaryLightGray;
    border-top: 0px;
  }

  &-option {
    border: 0px;
    width: 100%;
    padding: 16px;
    display: block;
    font-size: 1rem;
    cursor: pointer;
    text-align: left;
    padding-left: 24px;
    line-height: 1.5rem;
    color: $primaryDarkGray;
    background-color: transparent;

    &:last-child {
      border-bottom: 0px;
    }
  }

  &-expanded {
    .dropdown-field-dropdown {
      display: block;
    }

    .form-field {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      &::after {
        transform: translateX(-50%) rotate(180deg);
      }

      &-focused,
      &-dirty {
        border: 2px solid $primaryLightGray;
      }
    }

    .dropdown-field-dropdown {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}

.tabular-info {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #f5f5f5;
  border: 1px solid $primaryLightGray;

  &-line {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  &-multicolumn {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .tabular-info-column {
      flex: 1;
      margin: 0px 8px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &-withheader {
    display: block;
    align-items: initial;
    justify-content: initial;
  }

  &-header {
    margin-bottom: 12px;
  }

  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $primaryDarkGray;
  }

  &-heading,
  &-description {
    display: block;
    font-weight: 500;
  }

  &-heading {
    line-height: 1rem;
    margin-bottom: 4px;
    font-size: 0.625rem;
    color: $primaryDarkGray;
  }

  &-description {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $primaryDeepGreen;
  }
}

.global-loader {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 500;
  display: flex;
  position: fixed;
  cursor: not-allowed;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.65);

  &-content {
    width: 88px;
  }

  &-image {
    height: auto;
    max-width: 100%;
  }
}
