@import './colors';
@import './mixins';
@import './variables';

.dashboard {
  padding: 62px 24px;

  @include responsiveBelow($mobileMax) {
    padding: 32px 16px;
  }

  .menu-header {
    margin-bottom: 16px;
  }

  &-subtext {
    margin: 0px;
    margin-bottom: 40px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: transparentize($primaryDarkGray, 0.25);
  }

  &-meta {
    margin-bottom: 32px;
  }

  &-content {
    &-status {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-profile {
      margin-bottom: 24px;
      padding-bottom: 32px;
      border-bottom: 1px solid $primaryLightGray;
    }

    &-subsection {
      flex: 1;
      margin: 0px 8px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    &-subheading {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
      color: $primaryDeepGreen;
    }
  }

  .status {
    &-line {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
    }

    &-box {
      flex: 1;
      padding: 8px;
      display: flex;
      border-radius: 6px;
      margin-bottom: 16px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(39, 150, 60, 0.05);
      border: 1px solid rgba(39, 150, 60, 0.3);

      &-heading {
        font-weight: 500;
        text-align: center;
        margin-bottom: 8px;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $primaryDarkGray;
      }

      &-readings {
        margin-bottom: 16px;
      }

      &-counts,
      &-completed {
        display: block;
        text-align: center;
      }

      &-counts {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.25rem;
        color: $primaryDeepGreen;
      }

      &-completed {
        font-weight: 500;
        line-height: 1rem;
        font-size: 0.75rem;
        color: $primaryDarkGray;
      }

      &-disabled {
        .link-cta {
          opacity: 0.5;
          cursor: not-allowed;
          text-decoration: line-through;
        }
      }

      .link-cta {
        white-space: break-spaces;

        @include responsiveBelow($mobileMax) {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .secondary-cta {
    margin-top: 32px;
  }
}
