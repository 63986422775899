@import './colors';
@import './mixins';

.upload-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-container {
    width: 70px;
    height: 70px;
    position: relative;
    margin-right: 32px;

    &:last-child {
      margin-right: 0px;
    }
  }

  &-label {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    border: 2px dashed $primaryLightGray;
  }

  &-field {
    top: 4px;
    left: 4px;
    width: 1px;
    height: 1px;
    opacity: 0.01;
    position: absolute;

    &icon {
      width: 18px;
      height: 18px;
      display: block;
      background: url('../assets/images/plus-icon-grey.svg') center;
      background-size: contain;
    }
  }

  &-remove {
    top: -12px;
    z-index: 2;
    width: 24px;
    height: 24px;
    right: -12px;
    display: flex;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $primaryLightGray;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    &icon {
      width: 8px;
      height: 8px;
      opacity: 0.4;
      display: block;
      background: url('../assets/images/cross-icon-grey.svg') center;
      background-size: contain;
    }
  }

  &-photo {
    border-radius: 10px;
  }
}
