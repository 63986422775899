@import './colors';
@import './mixins';

.auth-login {
  height: 100%;
  display: flex;
  padding: 62px 24px;
  flex-direction: column;

  @include responsiveBelow($mobileMax) {
    padding: 32px 16px;
  }

  .title-header {
    margin-bottom: 40px;
  }

  &-content {
    flex: 1;
  }

  .form-field {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .primary-cta {
    margin-top: 32px;
  }
}
