// Define Common Colors
$white: #ffffff;
$black: #000000;

// Define Primary Theme Colors
$primaryDarkGray: #717d73;
$primaryLightGray: #e2e9e3;
$primaryDeepGreen: #102a13;
$primaryLightGreen: #27963c;
$primaryDeepOrange: #e96329;
$primaryBrightGreen: #87ce26;

// Define Complex Theme Colors & Gradients
@mixin greenGradient {
  background: linear-gradient(90deg, #27963c 0%, #87ce26 100%);
}
