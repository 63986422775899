@import './colors';
@import './mixins';

.question-describer {
  &-title {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $primaryDeepGreen;
  }

  &-description {
    margin: 0px;
    opacity: 0.85;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $primaryDarkGray;
  }
}
