@import './colors';
@import './mixins';

.menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  &-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &-right {
    margin-left: 32px;
  }

  &-heading {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.625rem;
    color: $primaryDeepGreen;
  }

  &-subheading {
    margin: 0px;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $primaryDarkGray;
  }

  .menu-hamburger {
    border: 0px;
    display: flex;
    cursor: pointer;
    padding: 6px 0px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &-icon {
      width: 28px;
      height: 14px;
      object-fit: fill;
    }
  }
}
