@import './colors';
@import './mixins';

.auth-register {
  padding: 62px 24px;

  @include responsiveBelow($mobileMax) {
    padding: 32px 16px;
  }

  .title-header {
    margin-bottom: 16px;
  }

  &-subtext {
    margin: 0px;
    margin-bottom: 40px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: transparentize($primaryDarkGray, 0.25);
  }

  &-fetch {
    border: 0px;
    padding: 0px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    background-color: $white;
    text-transform: uppercase;
    color: $primaryLightGreen;
  }

  .form-field {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .primary-cta {
    margin-top: 32px;
  }

  &-meta {
    margin-bottom: 40px;
  }
}

.auth-profile {
  .auth-register-subtext {
    margin-bottom: 24px;
  }

  &-subsection {
    .subsection {
      &-heading {
        font-weight: 500;
        margin-bottom: 8px;
        font-size: 1.125rem;
        line-height: 1.625rem;
        color: $primaryDeepGreen;
      }

      &-description {
        margin-top: 0px;
        margin-bottom: 24px;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: transparentize($primaryDarkGray, 0.25);
      }
    }
  }
}

.auth-teacher {
  .auth-register-meta {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 2px solid $primaryLightGray;
  }

  .auth-register-subtext {
    margin-bottom: 24px;
  }

  &-options {
    margin-bottom: 16px;
  }

  &-records {
    min-height: 50vh;

    &empty {
      display: flex;
      font-size: 1rem;
      font-weight: 500;
      align-items: center;
      line-height: 1.25rem;
      color: $primaryDarkGray;
      justify-content: center;
    }
  }

  .add-teacher {
    &-title {
      flex: 1;
      line-height: 2rem;
      font-size: 1.375rem;
      color: $primaryDeepGreen;
    }

    &-classes {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      justify-content: flex-start;
    }
  }

  .selected-class {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    padding: 8px 16px;
    margin-right: 16px;
    align-items: center;
    font-size: 0.875rem;
    border-radius: 30px;
    line-height: 1.25rem;
    color: $primaryDarkGray;
    background-color: $white;
    justify-content: space-between;
    border: 2px dashed $primaryLightGray;

    &-icon {
      width: 10px;
      height: 10px;
      margin-left: 12px;
      object-fit: cover;
    }
  }

  &-subsection {
    .subsection {
      &-heading {
        font-weight: 500;
        font-size: 1.125rem;
        margin-bottom: 24px;
        line-height: 1.625rem;
        color: $primaryDeepGreen;
      }

      &-description {
        margin-top: 0px;
        margin-bottom: 24px;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: transparentize($primaryDarkGray, 0.25);
      }
    }

    .form-field {
      margin-bottom: 24px;
    }

    .bool-cta {
      margin-right: 16px;
      margin-bottom: 8px;

      &:last-child {
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }

    &:last-child {
      margin-bottom: 56px;
    }
  }

  &-modaltitle {
    flex: 1;
    line-height: 2rem;
    font-size: 1.375rem;
    color: $primaryDeepGreen;
  }
}
